<template>
  <div class="settings">
    <v-container>
      <v-app id="app" class="mt-0">
        <v-container grid-list-xl>
          <ImageInput v-model="avatar">
            <div slot="activator">
              <v-avatar
                size="150px"
                v-ripple
                v-if="!avatar"
                class="grey lighten-3 mb-3"
              >
                <span>Hier klicken zum ändern</span>
              </v-avatar>
              <v-avatar size="150px" v-ripple v-else class="mb-3">
                <v-img
                  :src="avatar.imageURL"
                  alt="avatar"
                  @error="onImgError()"
                ></v-img>
              </v-avatar>
            </div>
          </ImageInput>
          <v-slide-x-transition>
            <div v-if="avatar && saved == false">
              <v-btn class="primary" @click="uploadImage" :loading="saving">
                Avatar speichern
              </v-btn>
            </div>
          </v-slide-x-transition>
        </v-container>
      </v-app>
    </v-container>
  </div>
</template>

<script>
import ImageInput from "@/components/ImageInput.vue";
import axios from "axios";

export default {
  data() {
    return {
      avatar: null,
      saving: false,
      saved: false,
    };
  },

  components: {
    ImageInput,
  },

  watch: {
    avatar: {
      handler: function () {
        if (this.avatar != null) {
          if (this.avatar.imageURL == this.getAvatar().imageURL) {
            this.saved = true;
          } else {
            this.saved = false;
          }
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.avatar = this.getAvatar();
  },

  computed: {
    currentUser() {
      return this.$store.getters.currentUserEmail;
    },
  },

  methods: {
    async uploadImage() {
      this.saving = true;

      console.log(this.avatar);

      this.$store
        .dispatch("uploadAvatar", {
          formData: this.avatar.formData,
        })
        .then((response) => {
          if (response.msg == null) {
            console.log("Image uploaded.");
            this.savedAvatar();
          } else {
            console.log(response.msg);
            this.saving = false;
          }
        });
    },

    savedAvatar() {
      this.saving = false;
      this.saved = true;
    },

    onImgError() {
      this.avatar = null;
    },

    getAvatar() {
      return {
        imageURL:
          axios.defaults.baseURL +
          "/images/avatars/" +
          this.currentUser +
          ".jpg",
      };
    },
  },
};
</script>

<style>
</style>